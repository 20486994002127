/* eslint-disable jsx-a11y/alt-text */
import { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { Button } from '../../components/Button'
import { Footer } from '../../components/Footer'
import {
  Goods,
  GoodsProps,
  mapPacks,
  mapPlans,
  mapProducts,
} from '../../components/Goods'
import { Header } from '../../components/Header'
import { useGet } from '../../hooks/get'
import { usePurchase } from '../../hooks/purchase'
import { useSign } from '../../hooks/sign'
import { useUser } from '../../hooks/user'
import s from './Pricing.module.css'

const Pricing: FC = () => {
  const { data: plans } = useGet<Dto.Plan[]>(`member_portal/plans`)
  const { data: packs } = useGet<Dto.Pack[]>(`member_portal/packs`)
  const { data: products } = useGet<Dto.Product[]>(`member_portal/products`)

  const { user } = useUser()
  const sign = useSign({ skipsPurchase: true })

  const [selectedItem, setSelectedItem] =
    useState<GoodsProps['items'][number]>()

  const purchase = usePurchase(selectedItem?.dto)

  const handleSubmit = async () => {
    if (!selectedItem) {
      toast.error('Please select an item first.')
      return
    }

    if (!user) {
      await sign.start()
      return
    }

    try {
      await purchase.submit()
      toast.success('Purchase succeeded.')
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <div className={s.root}>
        <Header />
        <div className={s.goods}>
          <Goods
            items={[
              ...mapPlans(plans || []),
              ...mapPacks(packs || []),
              ...mapProducts(products || []),
            ]}
            large
            groups
            onSelect={setSelectedItem}
          />
        </div>
        <Button
          block
          pending={purchase.pending}
          onClick={handleSubmit}
          className={s.pay}
        >
          Pay
        </Button>
        <Footer />
      </div>
      {sign.element}
      {purchase.element}
    </>
  )
}

export { Pricing }
