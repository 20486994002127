import { FC, useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Api } from '../../api'
import {
  showError,
  StripeSetup,
  StripeSetupProps,
} from '../../components/StripeSetup'
import s from './Stripe.module.css'

const Stripe: FC = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const submit = useCallback(
    async (payment: {
      customerId: unknown
      sessionId?: unknown
      paymentMethodType?: unknown
      paymentMethodId?: unknown
    }) => {
      try {
        await Api.post('member_portal/members', {
          lead_id: params.get('lead_id'),
          payment: {
            method: 'stripe',
            stripe_customer_id: payment.customerId,
            stripe_session_id: payment.sessionId,
            stripe_payment_method: payment.paymentMethodType,
            stripe_payment_method_id: payment.paymentMethodId,
          },
          plan_id: params.get('plan_id'),
          pack_id: params.get('pack_id'),
        })
      } catch (error) {
        showError(error as Error)
        return
      }

      toast.success('Congratulations, you can sign in now.')
      navigate('/classes', { replace: true })
    },
    [navigate, params]
  )

  const handleFinish: StripeSetupProps['onFinish'] = useCallback(
    (setupIntent, paymentMethodType, customerId) => {
      submit({
        customerId,
        paymentMethodType,
        paymentMethodId: setupIntent.payment_method,
      })
    },
    [submit]
  )

  useEffect(() => {
    const sessionId = params.get('stripe_session_id')

    if (!sessionId) {
      return
    }

    submit({ customerId: params.get('stripe_customer_id'), sessionId })
  }, [params, submit])

  return (
    <div className={s.root}>
      <StripeSetup
        clientSecret={params.get('client_secret')}
        connectedAccountId={params.get('connected_account_id')}
        customerId={params.get('customer_id')}
        onFinish={handleFinish}
      />
    </div>
  )
}

export { Stripe }
