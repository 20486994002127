import moment from 'moment-timezone'
import { FC, PropsWithChildren, useLayoutEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useGet } from '../../hooks/get'
import { configState } from '../../store/config'
import { gymState } from '../../store/gym'
import { touristTokenState } from '../../store/tourist'
import { userState } from '../../store/user'
import s from './Root.module.css'

const Root: FC<RootProps> = (props) => {
  /**
   * If a new token provided in URL, it means loading in a different gym,
   * so mark the state falsy to avoid using previous storage value.
   */
  const [safelyLoaded, setSafelyLoaded] = useState(!newTouristToken)
  const setTouristToken = useSetRecoilState(touristTokenState)
  const setGym = useSetRecoilState(gymState)
  const setUser = useSetRecoilState(userState)
  const [config, setConfig] = useRecoilState(configState)

  const { data: integration } = useGet<Dto.Integration>(
    safelyLoaded && 'member_portal/website_integration'
  )

  useLayoutEffect(() => {
    const newUrl = new URL(url)

    if (newTouristToken) {
      setUser(undefined)
      setTouristToken(newTouristToken)
      newUrl.searchParams.delete('api_key')
      setSafelyLoaded(true)
    }

    if (newConfig) {
      setConfig(JSON.parse(newConfig))
      newUrl.searchParams.delete('config')
    }

    if (integration) {
      const { customisation, gym } = integration
      setConfig(customisation)
      setGym(gym)
      moment.tz.setDefault(gym.time_zone_name)
    }

    if (newUrl.toString() === url.toString()) {
      return
    }

    window.history.replaceState(undefined, '', newUrl)
  }, [integration, setConfig, setGym, setTouristToken, setUser])

  if (!safelyLoaded) {
    return null
  }

  return (
    <div
      {...props}
      className={s.root}
      style={
        config && {
          '--theme-primary': config.themeColor,
          '--theme-secondary': `${config.themeColor}55`,
        }
      }
    />
  )
}

const url = new URL(window.location.href)
const newTouristToken = url.searchParams.get('api_key')
const newConfig = url.searchParams.get('config')

type RootProps = PropsWithChildren & {}

declare module 'csstype' {
  interface Properties {
    '--theme-primary'?: string
    '--theme-secondary'?: string
  }
}

export { Root }
export type { RootProps }
